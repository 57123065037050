export const sliderData = [
    {
      image: "https://live.staticflickr.com/65535/53783368794_ca29bf9620_w.jpg"
  ,
      heading: "Slide One",
      desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53783464290_1f95dc614a_w.jpg",
      heading: "Slide Two",
      desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53782105507_b7ddc23f9b_w.jpg",
      heading: "Slide Three",
      desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    }
  
  ];