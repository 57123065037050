export const sliderData = [
    {
      image:"https://live.staticflickr.com/65535/53783538780_2da698affe_w.jpg"
  ,
      heading: "Slide One",
      desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53783538770_8ddc716201_w.jpg",
      heading: "Slide Two",
      desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53783442684_81ff8858cf_w.jpg",
      heading: "Slide Three",
      desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53783330163_f2e6a0ffa1_w.jpg",
      heading: "Slide Four",
      desc: "This is the description of slide four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53782178507_3961a20ec0_w.jpg",
      heading: "Slide Five",
      desc: "This is the description of slide five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53783442659_289c5dd30a_w.jpg",
      heading: "Slide Six",
      desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
        image:"https://live.staticflickr.com/65535/53783116181_1e4fcf52ee_w.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image:"https://live.staticflickr.com/65535/53783538690_2184b2a043_w.jpg",
        heading: "Slide Two",
        desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image:"https://live.staticflickr.com/65535/53783538665_5a674d5621_w.jpg",
        heading: "Slide Three",
        desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53783442574_5125efb26d_w.jpg",
        heading: "Slide Four",
        desc: "This is the description of slide four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53783116121_e828013ae1_w.jpg",
        heading: "Slide Five",
        desc: "This is the description of slide five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53783329988_24d099fd8f_w.jpg",
        heading: "Slide Six",
        desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53783538645_b324129f15_w.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53783329933_6eb95bab23_w.jpg",
        heading: "Slide Two",
        desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image:"https://live.staticflickr.com/65535/53783442539_66985265fd_w.jpg",
        heading: "Slide Three",
        desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53783538565_033ca20f37_w.jpg",
        heading: "Slide Four",
        desc: "This is the description of slide four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53783116026_89228e6895_w.jpg",
        heading: "Slide Five",
        desc: "This is the description of slide five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image:"https://live.staticflickr.com/65535/53783442489_1270bf0ee7_w.jpg",
        heading: "Slide Six",
        desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image:"https://live.staticflickr.com/65535/53783329898_a207738b82_w.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53782178257_56d1acb1fb_w.jpg",
        heading: "Slide Two",
        desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      }
  
  ];