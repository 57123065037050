export const sliderData = [
    {
      image:"https://live.staticflickr.com/65535/53783058231_d3bcff65db_w.jpg",
      heading: "Slide One",
      desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53783271643_3f16778fd9_w.jpg",
      heading: "Slide Two",
      desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53782120617_5eafe97271_w.jpg",
      heading: "Slide Three",
      desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53783271673_86f423cba2_w.jpg",
      heading: "Slide Four",
      desc: "This is the description of slide four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53783384464_6f8d6fb54a_w.jpg",
      heading: "Slide Five",
      desc: "This is the description of slide five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53783271633_837ef188db_w.jpg",
      heading: "Slide Six",
      desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
        image: "https://live.staticflickr.com/65535/53783480420_4a9c83edff_w.jpg",
        heading: "Slide Seven",
        desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      }
  
  ];