import "./css/home.css"
import Footer from "./Footer"
import fb from "./images/fb.png"
import insta from "./images/insta.png"
import Slider from "./components/Sliders/Slider"
import Slider2 from "./components/Sliders/theArtofIllustrationSlider"
import Slider3 from "./components/Sliders/ikebanaSlider"
import Slider4 from "./components/Sliders/summerBluesSlider"
import Slider5 from "./components/Sliders/barnsAndBridgesSlider"
import Slider6 from "./components/Sliders/winterWonderSlider"
import Slider7 from "./components/Sliders/fairHavenShowSlider"
import Slider8 from "./components/Sliders/10x10Slider"
import Slider9 from "./components/Sliders/bottlesAndBrushesSlider"
import Slider10 from "./components/Sliders/StudioArtistTourSlider"

<style>
@import url('https://fonts.googleapis.com/css2?family=Comforter+Brush&display=swap');
</style>

export default function Home() {
    return <div className="container">

<section className="studioImg">
        <section class="topPage" id="info">
          <h4>Gribs Gallery</h4>
             <h1> 443-536-9198</h1>
           <h1>Follow us at: GribsGallery@Facebook.com </h1>
        </section>
            <br></br>
          <section class="column">
              <section class = "leftbox">     
                 <b>208 Main Street</b> <br></br><b> New Windsor</b><br></br><b>MD 21176 </b>
                  <h2>The Gallery is located behind the house and opens to Manning Dr.</h2>
                  <h2>There is plenty of parking in front of the gallery</h2>
         
          </section>
    
                  <section class = "rightbox"> 
                  
                    <b>Gribs gallery is Open: </b>
                      <h2> * 4-6 pm on friday Evenings</h2>
                      <h2> * 12-4 pm on Saturday Afternoons</h2>
                      </section>
              </section>
       
</section>

         
    <section className="divider"></section>
    <section className="upcomingEvents"id="upcominevents">
       <h2> Upcoming Events Here </h2>
        </section>
    <section className="divider"></section>
     <section className="pastEvents" id=" pastevents" >
        <section className="textBackground">
               <h2>Past Events</h2>
            </section>
               </section>
                  <section class = "exhib">
    

                            <section class = "descbin">
                                <br></br>
                                <section className="fibersAndFabrics">
                       
                                    <b>"Fibers and Fabrics”</b>
                              
                                     <br></br>
                                     <Slider />
                                     <br></br>
                                    <h3>A two artist exhibit featuring Weavings by Sharon Gribbin-Lindemon and Quilts by Mary Mahoney.</h3>   
                                </section> 
                                <br></br>
                                <hr className="gradient"></hr>
                   
                                <section className="theArtofIllustration">  
                        
                                    <b>“The Art Of Illustration”</b>
                                    <br></br>
                                    <Slider2 />
                                    <br></br>
                                    <h3>A glimpse into the artwork involved in creating a picture book.</h3>
                                 </section>  
                                 <br></br> 
                            <hr className="gradient"></hr>
                                <section className="ikebanaSliderData">
                 
                                    <b>“Ikebana - The Art of Japanese Flower Arranging” </b>
                                     <br></br> 
                                     <Slider3 /> 
                                     <br></br>  
                                     <h3>Master Ikebena Arranger, Emma Thompson gave a demonstration on this beautiful form of art.  Look for workshops in the future.</h3>
                                 </section>  
                                 <br></br>
                            <hr className="gradient"></hr>
                                <section className="summerBlues">
                       
                                    <b>“Summertime Blues, Greens Reds and …” </b>
                                    <br></br>
                                    <Slider4 />
                                    <br></br>
                                    <h3>An exhibit featuring the work of seven local artists done in a variety of media and genres.</h3>
                                </section>   
                                <br></br>
                           <hr className="gradient"></hr>
                                <section className="barnsAndBridges">
                             
                                    <b>“Barns and Bridges” </b>
                                     <br></br>
                                     <Slider5/> 
                                     <br></br>
                                     <h3>The beauty of Carroll County shone forth in this 11 artist exhibit that brought our barns and bridges to life in varied media.</h3>
                                   </section>
                                   <br></br>
                              <hr className="gradient"></hr>
                                   <section className="winterWonder">
                                     <b>“Winter Wonder” </b>
                                     <br></br>
                                    <Slider6 />
                                    <br></br>
                                     <h3>15 Artists exhibited their most beautiful winter scenes to warm the hearts of all who saw it.</h3>
                                    </section>
                                    <br></br>
                               <hr className="gradient"></hr>
                                    <section className="FairhavenAndCross">
                              
                                    <b>“The Fabulous Artist of Fairhaven and Cross Keys”</b>
                                    <br></br>
                                    <Slider7 />
                                    <br></br>
                                    <h3>This exhibit highlighted the awesome work  of artists from two local retirement communities.  It featured paintings, needle felting, wood working and turned bowls, and photography.</h3>
                                    </section>
                                    <br></br>
                               <hr className="gradient"></hr>
                                    <section className="exhibit10x10">
                               
                                        <b>
                                            10 x 10 Exhibit
                                        </b>
                                        <br></br>
                                        <Slider8 />
                                        <br></br>
                                    <h3></h3>
                                     </section> 
                                     <br></br> 
                                <hr className="gradient"></hr>
                                        <section className="bottlesAndBrushes">
                                      
                                            <b>
                                               Bottles And Brushes
                                            </b>
                                            <br></br>
                                            <Slider9 />
                                            <br></br>
                                            <h3></h3>
                                            </section> 
                                       <hr className="gradient"></hr>
                                            <br></br> 
                                             
                                            <section className="StudioArtistTour">
                                           
                                            <b>
                                            Studio Artist Tour
                                            </b>
                                            <br></br>
                                            <Slider10 />
                                            <br></br>
                                            <h3></h3>
                                            </section>  
                                                  <hr className="gradient"></hr>
                                            <br></br> 
                            </section>
                    </section>
               
     <section class="divider">
    </section>
   
    <section className="exhibitions" id="exhibitions">
       <h2>Exhibitions Go Here </h2>
        </section>
    <section className="divider"></section>
    <section class="bottomPage" iod="bottompage">
  <h1 >Stop by anytime the "OPEN" flag is flying or by appointment</h1> 
<h1>  If you would like to learn more about what we do at Gribs Gallery or to enter a show. Please Email us at:</h1>
  <a href="mailto:.johnlindemon@hbindustry.co"> <button class =" button1">email me!</button></a>

  </section>
        <Footer />
    </div>

}