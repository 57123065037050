export const sliderData = [
    {
      image: "https://live.staticflickr.com/65535/53782932726_d06cebd963_w.jpg",
      heading: "Slide One",
      desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53783256874_dcbc7808fe_w.jpg",
      heading: "Slide Two",
      desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image:"https://live.staticflickr.com/65535/53783144813_d9a6f7536b_w.jpg",
      heading: "Slide Three",
      desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53783256854_8073b49ce0_w.jpg",
      heading: "Slide Four",
      desc: "This is the description of slide four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53781995397_dc97b535db_w.jpg",
      heading: "Slide Five",
      desc: "This is the description of slide five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "https://live.staticflickr.com/65535/53782932751_3e214a0241_w.jpg",
      heading: "Slide Six",
      desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
        image: "https://live.staticflickr.com/65535/53781995407_8c6f01473d_w.jpg",
        heading: "Slide Seven",
        desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      }
    
  
  ];