
import React from "react";
import { BrowserRouter, Routes,Route } from "react-router-dom";
import "./App.css"
import Home from "./pages/Home"
import FAQ from "./pages/FAQ"
import Exhibitions from "./pages/Exhibitions"
import Apply from "./pages/Apply"

function App() {
  
  return (
    <div className="App">

    
  
  <BrowserRouter>

<Routes>
    <Route path="/" element= {<Home />} />
    <Route path="/Exhibitions" elements= {<Exhibitions />} />
    <Route path="/FAQ" element={<FAQ />} />
    <Route path="/Apply" element={<Apply />} />
</Routes>
  </BrowserRouter>
    
</div>
  );
}

export default App;
