export const sliderData = [
    {
      image: "https://live.staticflickr.com/65535/53866220157_278c83bd3b_w.jpg"
  ,
      heading: "Slide One",
      desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
        image: "https://live.staticflickr.com/65535/53867538285_709a854930_w.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53867537680_de08a9ccc4_w.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53867123291_a1556f7634_w.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53867373643_ddf1edb9cd_n.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },
      {
        image: "https://live.staticflickr.com/65535/53866216042_025a781506_w.jpg"
    ,
        heading: "Slide One",
        desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
      },

  
  ];