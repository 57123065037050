export const sliderData = [
  {
    image: "https://live.staticflickr.com/65535/53783093191_aecb577aa3_w.jpg"
,
    heading: "Slide One",
    desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
  {
    image: "https://live.staticflickr.com/65535/53783306393_806242cefe_w.jpg",
    heading: "Slide Two",
    desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
  {
    image:"https://live.staticflickr.com/65535/53782155262_a3148150d2_w.jpg",
    heading: "Slide Three",
    desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
  {
    image: "https://live.staticflickr.com/65535/53783419029_5c9dce4f25_w.jpg",
    heading: "Slide Four",
    desc: "This is the description of slide four Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
  {
    image: "https://live.staticflickr.com/65535/53783093176_c8219a01d3_w.jpg",
    heading: "Slide Five",
    desc: "This is the description of slide five Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
  {
    image: "https://live.staticflickr.com/65535/53783419034_c52753b625_w.jpg",
    heading: "Slide Six",
    desc: "This is the description of slide six Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  }

];